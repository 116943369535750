import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import QueryString from 'query-string';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { useCallback, useEffect, useState } from 'react';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { setIsUnderTechnicalReview } from '../store/features/reviewSlice';
import { useDispatch } from 'react-redux';
import { storeMultipleClaimProjectReports } from '../store/features/claimProjectReportsSlice';
const claimGroupService = {
  // Refactoring to set the isUnderReview state on claim group fetch.
  // To replace get when the refactoring is done.
  useGetClaimGroup(claimGroupId) {
    let initialState = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    const [claimGroup, setClaimGroup] = useState(initialState);
    const dispatch = useDispatch();
    const fetchClaimGroupAndUpdateTechnicalReviewState = useErrorHandling(useCallback(async claimGroupId => {
      if (claimGroupId) {
        const fetchedClaimGroup = await claimGroupService.get(claimGroupId);
        setClaimGroup(fetchedClaimGroup);
        dispatch(setIsUnderTechnicalReview(!!fetchedClaimGroup.active_change_set));
        dispatch(storeMultipleClaimProjectReports(!!fetchedClaimGroup.claim_project_reports));
      }
    }, [dispatch]));
    useEffect(() => {
      fetchClaimGroupAndUpdateTechnicalReviewState(claimGroupId);
    }, [fetchClaimGroupAndUpdateTechnicalReviewState, claimGroupId]);
    const resetClaimGroup = fetchClaimGroupAndUpdateTechnicalReviewState;
    return [claimGroup, setClaimGroup, resetClaimGroup];
  },
  useUpdateClaimGroup(claimGroupId, setClaimGroup) {
    return useErrorHandling(useCallback(async claimGroup => {
      const updatedClaimGroup = await claimGroupService.update(claimGroup);
      setClaimGroup(updatedClaimGroup);
    }, [setClaimGroup]));
  },
  useGetAllClaimGroups() {
    let initialParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const [claimGroups, setClaimGroups] = useState(null);
    const [totalPages, setTotalPages] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [params, setParams] = useState(initialParams);
    const fetchData = useErrorHandling(useCallback(async () => {
      setLoading(true);
      const response = await claimGroupService.all(QueryString.stringify(params));
      setClaimGroups(response.claim_groups);
      setTotalPages(response.pages);
    }, [params]));
    useEffect(() => {
      fetchData();
    }, [fetchData]);
    const handleSearch = query => {
      setParams({
        ...params,
        query,
        page: 1
      });
    };
    const handlePage = pageNumber => {
      window.scrollTo(0, 0);
      setParams({
        ...params,
        page: pageNumber
      });
    };
    const deleteClaimGroup = useErrorHandling(useCallback(async claimGroupId => {
      if (window.confirm('Are you sure you wish to delete this claim group ?')) {
        setLoading(true);
        try {
          await claimGroupService.delete(claimGroupId);
          await fetchData();
        } catch (error) {
          dispatch(handleError(error));
        }
        ;
        setLoading(false);
      }
    }, []));
    return [claimGroups, totalPages, loading, handleSearch, handlePage, deleteClaimGroup];
  },
  get(claimGroupId) {
    return fetch("/api/claim_groups/".concat(claimGroupId), request.get);
  },
  update(claim_group) {
    return fetch("/api/claim_groups/".concat(claim_group.id), {
      ...request.put,
      body: JSON.stringify({
        claim_group
      })
    });
  },
  create(claim_group) {
    return fetch('/api/claim_groups/', {
      ...request.post,
      body: JSON.stringify({
        claim_group
      })
    });
  },
  all(params) {
    return fetch("/api/claim_groups?".concat(params), request.get);
  },
  delete(claimGroupId) {
    return fetch("/api/claim_groups/".concat(claimGroupId), {
      ...request.delete
    });
  }
};
export default claimGroupService;