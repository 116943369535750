import { useState, useEffect, useCallback } from 'react';
import QueryString from 'query-string';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';
import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';

const myClaimsService = {
  useGetAllClaims(initialParams = {}) {
    const [claims, setClaims] = useState(null);
    const [totalPages, setTotalPages] = useState(null);
    const [loading, setLoading] = useState(false);

    const [params, setParams] = useState(initialParams);

    const fetchData = useErrorHandling(useCallback(async () => {
      setLoading(true);
      const response = await myClaimsService.all(QueryString.stringify(params));
      setClaims(response.claims);
      setTotalPages(response.pages);
    }, [params]));

    useEffect(() => {
      fetchData();
    }, [fetchData]);

    const handleSearch = (query) => {
      setParams({ ...params, query, page: 1 });
    }

    const handlePage = (pageNumber) => {
      setParams({ ...params, page: pageNumber });
    }

    return [claims, totalPages, loading, handleSearch, handlePage];
  },
  all(params) {
    return fetch(`/api/my/claims?${params}`, request.get);
  },
}

export default myClaimsService;
