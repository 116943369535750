import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
const rndCollaborateContactsService = {
  all(groupId) {
    return fetch("/api/claim_groups/".concat(groupId, "/rnd_collaborate_contacts"), request.get);
  },
  delete(contactConsumable) {
    return fetch("/api/claim_groups/".concat(contactConsumable.groupId, "/rnd_collaborate_contacts/").concat(contactConsumable.contactId), request.delete);
  },
  update(contactConsumable) {
    return fetch("/api/claim_groups/".concat(contactConsumable.groupId, "/rnd_collaborate_contacts/").concat(contactConsumable.contact.id), {
      ...request.put,
      body: JSON.stringify({
        rnd_collaborate_contact: contactConsumable.contact
      })
    });
  },
  create(contactConsumable) {
    return fetch("/api/claim_groups/".concat(contactConsumable.groupId, "/rnd_collaborate_contacts"), {
      ...request.post,
      body: JSON.stringify({
        rnd_collaborate_contact: contactConsumable.newContact
      })
    });
  }
};
export default rndCollaborateContactsService;