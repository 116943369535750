import { Card, Table } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';

const NUMBER_OF_ITEMS = 5;

const MyTasks = ({ tasks }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();

	const columns = [
		{
			text: '',
		},
	];

	if(!tasks) {
		return null;
	}

	const rows = tasks.slice(0, NUMBER_OF_ITEMS).map((task) => {
		return {
			taskData: [task.title, `${location.pathname}/time_tracking/my_tasks?page=1&query=`],
		};
	})

	const handleViewAllTasks = () => {
		history.push(`${location.pathname}/time_tracking/my_tasks?page=1&query=`);
	}

	return (
		<Card className='px-4 py-3 h-100'>
			<h3 className='font-weight-bold'>{t('my_tasks')}</h3>
			{rows.length === 0
				? <p className='text-center text-secondary'>{t('no_tasks_assigned')}</p>
				: <Table className='table borderless'>
					<thead>
						<tr>
							{columns.map((column, index) => {
								return (
									<th key={index}>{column.text}</th>
								)
							})}
						</tr>
					</thead>
					<tbody>
						{rows.map((row, index) => {
							return (
								<tr key={index}>
									<td>
										<Link to={row.taskData[1]}>{row.taskData[0]}</Link>
									</td>
								</tr>
							)
						})}
					</tbody>
				</Table>}
			<hr className='mt-auto' />
			<Button className='mr-auto' onClick={handleViewAllTasks} variant='text'>{t('view_all_tasks')}</Button>
		</Card>
	);
}

export default MyTasks;
