import { Card, Table } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import { companiesService } from '@services/task_management/companies.service';

const NUMBER_OF_ITEMS = 5;

const MyCompanies = ({ companies, claimGroups, tasks }) => {
	const [taskCompanies] = companiesService.useGetAllCompanies();
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();

	const columns = [
		{
			text: t('company'),
		},
		{
			text: t('projects'),
		},
		{
			text: t('tasks'),
		}
	];

	if(!companies || !claimGroups || !tasks || !taskCompanies) {
		return null;
	}

	const getProjectsNumber = (company) => {
		return claimGroups.filter((claimGroup) => claimGroup.company_id === company.id).reduce((acc, claimGroup) => {
			return acc + claimGroup.projects_count;
		}, 0);
	}

	const getTasksNumber = (company) => {
		const currentCompany = taskCompanies.find((taskCompany) => taskCompany.id === company.id);
		return currentCompany ? currentCompany.number_of_tasks : 0;
	}

	const rows = companies.slice(0, NUMBER_OF_ITEMS).map((company) => {
		return {
			companyData: [company.name, `${location.pathname}/companies/${company.id}/master`],
			companyProjectsNumber: getProjectsNumber(company),
			companyTasksNumber: getTasksNumber(company),
		};
	})

	const handleViewAllCompanies = () => {
		history.push(`${location.pathname}/companies`);
	}

	return (
		<Card className='px-4 py-3 h-100'>
			<h3 className='font-weight-bold'>{t('my_companies')}</h3>
			{companies.langth === 0
				? <p className='text-center text-secondary'>{t('no_companies_assigned')}</p>
				: <Table className='table borderless'>
					<thead>
						<tr>
							{columns.map((column, index) => {
								return (
									<th className={index === 0 ? 'text-start' : 'text-center'} key={index}>{column.text}</th>
								)
							})}
						</tr>
					</thead>
					<tbody>
						{rows.map((row, index) => {
							return (
								<tr key={index}>
									<td>
										<Link to={row.companyData[1]}>{row.companyData[0]}</Link>
									</td>
									<td className='text-center'>{row.companyProjectsNumber}</td>
									<td className='text-center'>{row.companyTasksNumber}</td>
								</tr>
							)
						})}
					</tbody>
				</Table>}
			<hr className='mt-auto' />
			<Button className='mr-auto' onClick={handleViewAllCompanies} variant='text'>{t('view_all_companies')}</Button>
		</Card>
	)
}

export default MyCompanies;
