import { Table } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import { Loading } from '@rd-web-markets/shared/dist/util';

const RecentClaimGroups = ({ claimGroups, recentClaimGroupIds }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();

	const columns = [
		{
			text: '',
		},
		{
			text: t('active_phase'),
		},
		{
			text: t('projects'),
		},
		{
			text: t('in_progress'),
		},
		{
			text: t('costs_collected'),
		}
	];

	if(!claimGroups) {
		return <Loading />;
	}

	const getCurrentTask = (claimGroup) => {
		const task = claimGroup.tasks.find((task) => task.status === 'in_progress');
		return task ? t(task.category_title) : t('not_started');
	}

	const rows = recentClaimGroupIds ? recentClaimGroupIds.map((id) => {
		const claimGroup = claimGroups.find((claimGroup) => String(claimGroup.id) === id);
		if(claimGroup) {
			return {
				claimGroupData: [claimGroup.name, `${location.pathname}/companies/${claimGroup.company_id}/${claimGroup.id}/master`],
				activePhase: getCurrentTask(claimGroup),
				projects: claimGroup.projects_count,
				inProgress: claimGroup.in_progress_projects,
				costsCollected: claimGroup.cost_collected_projects,
			};
		}
	}) : []

	const handleViewAllClaimGroups = () => {
		history.push(`${location.pathname}/all_claim_groups?page=1&query=`);
	}

	return (
		<>
			{!recentClaimGroupIds
				? <p className='text-center text-secondary'>{t('no_recent_claim_groups')}</p>
				: <Table className='table borderless'>
					<thead>
						<tr>
							{columns.map((column, index) => {
								return (
									<th className='text-center' key={index}>{column.text}</th>
								)
							})}
						</tr>
					</thead>
					<tbody>
						{rows.map((row, index) => {
							return (
								<tr key={index}>
									<td>
										<Link to={row.claimGroupData[1]}>{row.claimGroupData[0]}</Link>
									</td>
									<td className='text-center'>{row.activePhase}</td>
									<td className='text-center'>{row.projects}</td>
									<td className='text-center'>{row.inProgress}</td>
									<td className='text-center'>{row.costsCollected}</td>
								</tr>
							)
						})}
					</tbody>
				</Table>}
			<hr />
			<Button variant='text' onClick={handleViewAllClaimGroups}>
				{t('view_all_claim_groups')}
			</Button>
		</>
	);
};

export default RecentClaimGroups;