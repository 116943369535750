import { Card, Table } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';

const NUMBER_OF_ITEMS = 5;

const MyClaims = ({ claims }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();

	if (!claims) {
		return null;
	}

	const handleViewAllClaims = () => {
		history.push(`${location.pathname}/all_claims/?page=1&query=`);
	}

	return (
		<Card className='px-4 py-3 h-100'>
			<h3 className='font-weight-bold'>{t('my_claims')}</h3>
			{claims.length === 0
				? <p className='text-center text-secondary'>{t('no_claims_assigned')}</p>
				: <Table className='table borderless'>
					<thead>
						<tr>
							<th>{t('claim')}</th>
						</tr>
					</thead>
					<tbody>
						{claims.slice(0, NUMBER_OF_ITEMS).map((claim, index) => {
							return (
								<tr key={index}>
									<td><Link to={`${location.pathname}/companies/${claim.company.id}/${claim.claim_group_id}/master`}>{claim.name}</Link></td>
								</tr>
							)
						})}
					</tbody>
				</Table>}
			<hr className='mt-auto' />
			<Button className='mr-auto' onClick={handleViewAllClaims} variant='text'>{t('view_all_claims')}</Button>
		</Card>
	)
}

export default MyClaims;
