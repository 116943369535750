import { useCallback, useEffect, useState } from 'react';
import QueryString from 'query-string';
import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { useErrorHandling } from '@rd-web-markets/shared/dist/hooks/useErrorHandling';

export const companiesService = {
  useGetAllCompanies(initialParams = {}) {
    const [companies, setCompanies] = useState(null);
    const [loading, setLoading] = useState(false);

    const [params, setParams] = useState(initialParams);

    const fetchData = useErrorHandling(useCallback(async () => {
      setLoading(true);
      const response = await companiesService.all(QueryString.stringify(params));
      setCompanies(response);
    }, [params]));

    useEffect(() => {
      fetchData();
    }, [fetchData]);

    return [companies, loading];
  },
  all: (queryParams = null) => {
    return fetch(`/api/time_tracking/companies?${queryParams}`, request.get);
  }
};
