import { Table } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Loading } from '@rd-web-markets/shared/dist/util';

const RecentCompanies = ({ companies, recentCompanyIds }) => {
	const location = useLocation();
	const { t } = useTranslation();

	const columns = [
		{
			text: '',
		}
	];

	if(!companies) {
		return <Loading />;
	}

	const rows = recentCompanyIds ? recentCompanyIds.map((id) => {
		const company = companies.find((company) => String(company.id) === id);
		if(company) {
			return {
				companyData: [company.name, `${location.pathname}/companies/${company.id}/master`],
			};
		}
	}) : []

	return (
		<>
			{!recentCompanyIds
				? <p className='text-center text-secondary'>{t('no_recent_companies')}</p>
				: <Table className='table borderless'>
					<thead>
						<tr>
							{columns.map((column, index) => {
								return (
									<th className='text-center' key={index}>{column.text}</th>
								)
							})}
						</tr>
					</thead>
					<tbody>
						{rows.map((row, index) => {
							return (
								<tr key={index}>
									<td>
										<Link to={row.companyData[1]}>{row.companyData[0]}</Link>
									</td>
								</tr>
							)
						})}
					</tbody>
				</Table>}
		</>
	);
};

export default RecentCompanies;