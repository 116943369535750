import { Card, Tabs, Tab } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RecentClaimGroups from './RecentClaimGroups';
import RecentCompanies from './RecentCompanies';

const RecentActivity = ({ claimGroups, recentClaimGroupIds, companies, recentCompanyIds }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();

	return (
		<Card className='px-4 py-3 h-100'>
			<h3 className='font-weight-bold'>{t('recent_activity')}</h3>
			<Tabs className='mb-2' defaultActiveKey={'claim_groups'}>
				<Tab eventKey='claim_groups' title={t('claim_groups')}>
					<RecentClaimGroups claimGroups={claimGroups} recentClaimGroupIds={recentClaimGroupIds} />
				</Tab>
				<Tab eventKey='companies' title={t('companies')}>
					<RecentCompanies companies={companies} recentCompanyIds={recentCompanyIds} />
				</Tab>
			</Tabs>
			
		</Card>
	)
}

export default RecentActivity;
