import { Card, Table } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CommonActions = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const location = useLocation();

	const actions = [
		{
			text: '+ Add company',
			link: `${location.pathname}/companies/new`
		},
		{
			text: '+ Add user',
			link: `${location.pathname}/users/new`
		}
	]

	return (
		<Card className='px-4 py-3 h-100'>
			<h3 className='font-weight-bold'>{t('common_actions')}</h3>
			<Table className='table borderless'>
				<thead>
					<tr>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{actions.map((action, index) => {
						return (
							<tr key={index}>
								<td><Link to={action.link}>{action.text}</Link></td>
							</tr>
						)
					})}
				</tbody>
			</Table>

		</Card>
	);
}

export default CommonActions;